#maindiv {
    width:80%;
    height:100%;
}

@media (max-width:768px) {
    #maindiv {
        width:100%;
        height:100%;
    }
}